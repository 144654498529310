<template>
  <div :class="['app-checkbox', `app-checkbox--${type}`]">
    <label class="app-checkbox__label">
      <input
        ref="input"
        type="checkbox"
        class="app-checkbox__input"
        :value="value"
        :checked="value === true" 
        @click="handleCheck"
        :disabled="disabled"
      />
      <span
        :class="{ 'app-checkbox__text': !disabled, 'text-gray': disabled }"
        v-if="label"
      >
        {{ $t(label) }}
      </span>
      <i v-if="icon" :class="'ml-2 mdi mdi-' + icon"></i>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue'

const input = ref<HTMLInputElement | null>(null)

const props = withDefaults(
  defineProps<{
    label?: string
    disabled?: boolean
    readonly?: boolean
    type?: string
    value?: boolean | null // can be null if indeterminate
    icon?: string
  }>(),
  {
    type: 'primary', // primary, secondary, text, secondary-text, danger
  }
)

watch(
  () => props.value,
  (newValue) => {
    // set the indetermiante value if the value is null
    // this works due to daisyUI styling
    if (input.value) {
      input.value.indeterminate = newValue === null
      input.value.checked = newValue === true
    }
  },
  { immediate: true }
)

const emit = defineEmits<{
  (e: 'check', event: Event): void
  (e: 'input', oldValue: boolean | null, value: boolean): void
}>()

const handleCheck = (event: Event) => {
  if (props.disabled) return
  let newValue: boolean | null
  if (props.value === null) {
    newValue = false
  } else {
    newValue = !props.value
  }

  emit('input', props.value ?? null, newValue)
  emit('check', event)
}
</script>
